import * as Yup from 'yup';

export default Yup.object().shape({
  hasAntiFraud: Yup.boolean(),
  expirationDate: Yup.string(),
  readingAmount: Yup.number(),
  listeningAmount: Yup.number(),
  writingAmount: Yup.number(),
  speakingAmount: Yup.number(),
  fullTestAmount: Yup.number(),
  readingPracticeAmount: Yup.number(),
  listeningPracticeAmount: Yup.number(),
  writingPracticeAmount: Yup.number(),
  speakingPracticeAmount: Yup.number(),
  cefrAmount: Yup.number(),
  name: Yup.string(),
  licenseType: Yup.string(),
  readingWritingAmount: Yup.number(),
  readingWritingPracticeAmount: Yup.number(),
  studentId: Yup.string(),
  customCategoriesAmount: Yup.array(),
  photoStudent: Yup.boolean(),
  photoUploadId: Yup.boolean(),
  photoUploadType: Yup.string(),
  takePictureNextId: Yup.boolean(),
  screenShare: Yup.boolean(),
  roomScan360: Yup.boolean(),
  roomScanRandom: Yup.boolean(),
});
