import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useToggleDropdown } from 'modules/Core/Hooks';
import { ArrowDownIcon } from '@edusynch/edusynch-svg-icons';
import * as S from './ButtonDropdown.styles';

export const ButtonDropdown = ({
  title,
  Icon,
  iconColor,
  iconSize = '1.2rem',
  iconOnly,
  arrowDownIconColor,
  closeOnClickInside = true,
  hasArrowDown = true,
  placement = 'right',
  children,
  showOverlay = true,
  onCloseDropdown,
  dataCy,
  setCloseDropdown,
  ...props
}) => {
  const dropdownRef = React.useRef();
  const buttonRef = React.useRef();

  const { showDropdown, setShowDropdown } = useToggleDropdown(
    buttonRef,
    dropdownRef,
    closeOnClickInside,
    onCloseDropdown
  );

  useEffect(() => {
    if (setCloseDropdown) {
      setShowDropdown(false);
    }
  }, [setCloseDropdown]);

  return (
    <S.Container isFilterOpen={!!showDropdown}>
      {showDropdown && showOverlay && <div className="overlay" />}
      <S.Button
        ref={buttonRef}
        arrowDownIconColor={arrowDownIconColor}
        {...props}
        onClick={() => setShowDropdown(!showDropdown)}
        iconOnly={iconOnly}
        data-cy={dataCy}
      >
        {Icon && (
          <Icon
            color={iconColor}
            width={iconSize}
            height={iconSize}
            left="true"
          />
        )}
        {title && title}
        {hasArrowDown && <ArrowDownIcon width={12} right="true" />}
      </S.Button>
      {showDropdown && (
        <S.Dropdown
          data-cy="dropdown.list"
          ref={dropdownRef}
          placement={placement}
          iconOnly={iconOnly}
        >
          {children}
        </S.Dropdown>
      )}
    </S.Container>
  );
};

ButtonDropdown.propTypes = {
  title: PropTypes.string,
  Icon: PropTypes.func,
  iconColor: PropTypes.string,
  iconSize: PropTypes.number,
  iconOnly: PropTypes.bool,
  arrowDownIconColor: PropTypes.string,
  closeOnClickInside: PropTypes.bool,
  hasArrowDown: PropTypes.bool,
  placement: PropTypes.string,
  dataCy: PropTypes.string,
  showOverlay: PropTypes.bool,
  onCloseDropdown: PropTypes.func,
  children: PropTypes.node.isRequired,
  setCloseDropdown: PropTypes.func,
};
