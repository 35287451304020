const createLicenseModel = (license = {}) => ({
  id: license.id || null,
  name: license.name || '',
  amount: license.amount || 0,
  testId: license.test?.id || '',
  groupId: license.group?.id || '',
  unitId: license.unit?.id || '',
  schoolId: license.school?.id || '',
  classId: license.student_class?.id || '',
  enableApplyPhotos: license.enable_apply_photos || false,
  expirationDate: license.expiration_date || '',
  licenseType: license.license_type || 'EDUSYNCH',
  readingAmount: license.reading_amount || 0,
  listeningAmount: license.listening_amount || 0,
  writingAmount: license.writing_amount || 0,
  speakingAmount: license.speaking_amount || 0,
  fullTestAmount: license.full_test_amount || 0,
  readingPracticeAmount: license.reading_practice_amount || 0,
  listeningPracticeAmount: license.listening_practice_amount || 0,
  writingPracticeAmount: license.writing_practice_amount || 0,
  speakingPracticeAmount: license.speaking_practice_amount || 0,
  availableAmount: license.available_amount || 0,
  cefrAmount: license.cefr_amount || 0,
  mcerAmount: license.mcer_amount || 0,
  readingWritingAmount: license.reading_writing_amount || 0,
  readingWritingPracticeAmount: license.reading_writing_practice_amount || 0,
  photoStudent: license.photo_student || false,
  photoUploadId: license.photo_upload_id || false,
  photoUploadType: license.photo_upload_type || false,
  takePictureNextId: license.take_picture_next_id || false,
  screenShare: license.screen_share || false,
  roomScan360: license.room_scan_360 || false,
  roomScanRandom: license.room_scan_random || false,
});

const createLicenseCustomTestModel = (license = {}) => {
  return {
    id: license.id || null,
    name: license.name || '',
    amount: license.amount || 0,
    customTestId: license.custom_test?.id || '',
    whitelabel: license.whitelabel?.name || '',
    whitelabelId: license.whitelabel?.id || '',
    groupId: license.group?.id || '',
    unitId: license.unit?.id || '',
    schoolId: license.school?.id || '',
    classId: license.student_class?.id || '',
    enableApplyPhotos: license.enable_apply_photos || false,
    expirationDate: license.expiration_date || '',
    licenseType: license.license_type || 'EDUSYNCH',
    readingAmount: license.reading_amount || 0,
    listeningAmount: license.listening_amount || 0,
    writingAmount: license.writing_amount || 0,
    speakingAmount: license.speaking_amount || 0,
    fullTestAmount: license.full_test_amount || 0,
    readingPracticeAmount: license.reading_practice_amount || 0,
    listeningPracticeAmount: license.listening_practice_amount || 0,
    writingPracticeAmount: license.writing_practice_amount || 0,
    speakingPracticeAmount: license.speaking_practice_amount || 0,
    availableAmount: license.available_amount || 0,
    cefrAmount: license.cefr_amount || 0,
    mcerAmount: license.mcer_amount || 0,
    readingWritingAmount: license.reading_writing_amount || 0,
    readingWritingPracticeAmount: license.reading_writing_practice_amount || 0,
    customCategoriesAmount: license.custom_categories_amount || [],
    photoStudent: license.photo_student || false,
    photoUploadId: license.photo_upload_id || false,
    photoUploadType: license.photo_upload_type || false,
    takePictureNextId: license.take_picture_next_id || false,
    screenShare: license.screen_share || false,
    roomScan360: license.room_scan_360 || false,
    roomScanRandom: license.room_scan_random || false,
  };
};

const createLicenseProctoringModel = (license = {}) => ({
  id: license.id || null,
  name: license.name || '',
  proctoring_amount_max: license.proctoring_amount_max || 0,
  expiration_date: license.expiration_date || '',
  license_type: license.license_type || 'EDUSYNCH',
  product: license.product || 'proctoring',
  key_type: license.key_type || 'student',
  external_application_id: license.external_application?.id || '',
  eproctoring_license_type: license.eproctoring_license_type || 'per_student',
});

const createLicenseRequestModel = (license = {}) => {
  return {
    id: license.id,
    name: license.name,
    amount: license.amount,
    test_id: license.testId,
    group_id: license.groupId,
    unit_id: license.unitId,
    school_id: license.schoolId,
    student_class_id: license.classId,
    enable_apply_photos: license.enableApplyPhotos,
    expiration_date: license.expirationDate,
    license_type: license.licenseType,
    reading_amount: license.readingAmount,
    listening_amount: license.listeningAmount,
    writing_amount: license.writingAmount,
    speaking_amount: license.speakingAmount,
    full_test_amount: license.fullTestAmount,
    reading_practice_amount: license.readingPracticeAmount,
    listening_practice_amount: license.listeningPracticeAmount,
    writing_practice_amount: license.writingPracticeAmount,
    speaking_practice_amount: license.speakingPracticeAmount,
    available_amount: license.availableAmount,
    cefr_amount: license.cefrAmount,
    mcer_amount: license.mcerAmount,
    reading_writing_amount: license.readingWritingAmount,
    reading_writing_practice_amount: license.readingWritingPracticeAmount,
    photo_student: license.photoStudent,
    photo_upload_id: license.photoUploadId,
    photo_upload_type: license.photoUploadType,
    take_picture_next_id: license.takePictureNextId,
    screen_share: license.screenShare,
    room_scan_360: license.roomScan360,
    room_scan_random: license.roomScanRandom,
  };
};

const createLicenseCustomTestRequestModel = (license = {}) => {
  return {
    id: license.id,
    name: license.name,
    amount: license.amount,
    custom_test_id: license.customTestId,
    group_id: license.groupId,
    whitelabel_id: license.whitelabelId,
    unit_id: license.unitId,
    school_id: license.schoolId,
    student_class_id: license.classId,
    enable_apply_photos: license.enableApplyPhotos,
    expiration_date: license.expirationDate,
    license_type: license.licenseType,
    reading_amount: license.readingAmount,
    listening_amount: license.listeningAmount,
    writing_amount: license.writingAmount,
    speaking_amount: license.speakingAmount,
    full_test_amount: license.fullTestAmount,
    reading_practice_amount: license.readingPracticeAmount,
    listening_practice_amount: license.listeningPracticeAmount,
    writing_practice_amount: license.writingPracticeAmount,
    speaking_practice_amount: license.speakingPracticeAmount,
    available_amount: license.availableAmount,
    cefr_amount: license.cefrAmount,
    mcer_amount: license.mcerAmount,
    reading_writing_amount: license.readingWritingAmount,
    reading_writing_practice_amount: license.readingWritingPracticeAmount,
    custom_categories_amount: license.customCategoriesAmount,
    photo_student: license.photoStudent,
    photo_upload_id: license.photoUploadId,
    photo_upload_type: license.photoUploadType,
    take_picture_next_id: license.takePictureNextId,
    screen_share: license.screenShare,
    room_scan_360: license.roomScan360,
    room_scan_random: license.roomScanRandom,
  };
};

const licenseProctoringTypes = [
  {
    id: 'per_student',
    name: 'Student',
  },
  {
    id: 'per_session',
    name: 'Session',
  },
];

const licenseTypes = [
  {
    id: 'EDUSYNCH',
    name: 'EduSynch',
  },
  {
    id: 'SUBSCRIPTION',
    name: 'Subscription',
  },
];

export {
  createLicenseModel,
  createLicenseCustomTestModel,
  createLicenseProctoringModel,
  createLicenseRequestModel,
  createLicenseCustomTestRequestModel,
  licenseProctoringTypes,
  licenseTypes,
};
