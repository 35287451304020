import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  ANTIFRAUDS_URL,
  ANTIFRAUDS_CUSTOM_TESTS_URL,
  ANTIFRAUD_EXPORT_URL,
} from 'modules/Api/Routes';
import { getISOStringFromStringDate } from 'modules/Utils';
import { formDataFromObj } from 'modules/Api/RequestData';
import {
  getUTCDefaultStartDate,
  getUTCDefaultEndDate,
} from 'modules/Utils/Date';

let cancelToken;

const startDate = getUTCDefaultStartDate();
const endDate = getUTCDefaultEndDate();

const initialState = {
  loading: false,
  error: null,
  exportFilter: {
    incidentLevel: 'all',
    integration: 'all',
    startDate: startDate,
    endDate: endDate,
    extension: 'csv',
    link: '',
    loading: false,
  },
  data: {
    page: 1,
    perPage: 10,
    search: '',
    customTestId: null,
    incidentLevel: '',
    integration: '',
    startDate: startDate,
    endDate: endDate,
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
  updateAntifraudData: {
    loading: false,
    id: '',
  },
};

const antifraudSlice = createSlice({
  name: 'antifraud',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    requestAntifraud: (state) => {
      state.loading = true;
      state.error = null;
    },
    requestUpdateComments: (state) => {
      state.error = null;
    },
    requestUpdateAntifraud: (state, action) => {
      state.updateAntifraudData.loading = true;
      state.updateAntifraudData.id = action.payload.id;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    changeAntifraudFilters: (state, action) => {
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
      state.data.search = action.payload?.search || '';
      state.data.customTestId =
        action.payload?.test_id || state.data.customTestId;
      state.data.incidentLevel = action.payload?.incident_level || '';
      state.data.integration = action.payload?.external_application_id || '';
      state.data.startDate = action.payload?.start_date || state.data.startDate;
      state.data.endDate = action.payload?.end_date || state.data.endDate;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    receiveUpdateAntifraudRequestSuccess: (state) => {
      state.updateAntifraudData.loading = false;
      state.updateAntifraudData.id = '';
    },
    /**
     * receive a success antifrauds list response
     */
    receiveAntifraudList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        antifrauds: action.payload.exercises,
        total: action.payload.total_items,
      };
    },
    clearAntifraudList: (state) => {
      state.loading = false;
      state.data = {
        page: 1,
        perPage: 10,
        startDate,
        endDate,
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
    },
    clearAntifraudFilters: (state) => {
      state.data.customTestId = null;
      state.data.incidentLevel = '';
      state.data.integration = '';
      state.data.startDate = getUTCDefaultStartDate();
      state.data.endDate = getUTCDefaultEndDate();
      state.data.search = '';
      state.data.page = 1;
      state.data.perPage = 10;
      state.data.sort.sortType = null;
      state.data.sort.sortBy = null;
    },
    /**
     * receive an error response
     */
    receiveAntifraudError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changeAntifraudPage: (state, action) => {
      state.data.page = action.payload;
    },
    changeAntifraudSearch: (state, action) => {
      state.data.search = action.payload.search;
      state.data.page = 1;
    },
    updateAntifraudReviewedField: (state, action) => {
      const updatedAntifraud = action.payload;

      const antifraudObj = state.data?.antifrauds?.find(
        (antifraud) => antifraud.id === updatedAntifraud.id
      );

      if (!antifraudObj) return;

      antifraudObj.reviewed = updatedAntifraud.reviewed;
    },
    changeExportFilter: (state, action) => {
      state.exportFilter = {
        ...state.exportFilter,
        [action.payload?.param]: action.payload?.value,
      };
    },
    requestExportLinks: (state) => {
      state.exportFilter.link = '';
      state.exportFilter.loading = true;
    },
    receiveExportLinks: (state, action) => {
      state.exportFilter.loading = false;
      state.exportFilter.link = action?.payload?.link;
    },
    receiveExportLinksError: (state, action) => {
      state.exportFilter.loading = false;
      state.error = action.payload;
    },
    resetExportValues: (state) => {
      state.exportFilter = {
        incidentLevel: state.data.incidentLevel || 'all',
        integration: state.data.integration || 'all',
        startDate: state.data.startDate,
        endDate: state.data.endDate,
        extension: 'csv',
        loading: false,
      };
    },
  },
});

const Actions = antifraudSlice.actions;

const Selectors = {
  fetchListData: (state) => state.antifraud,
  antifraudLoading: ({ antifraud: { loading } }) => ({ loading }),
};

const Async = {
  fetchAntifraudBaseTestsList: () => async (dispatch, getState) => {
    const {
      antifraud: {
        data: {
          page,
          perPage,
          search,
          incidentLevel,
          integration,
          startDate,
          endDate,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestAntifraud());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: ANTIFRAUDS_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          incident_level: incidentLevel || undefined,
          external_application_id: integration || undefined,
          sort: sortType,
          sort_by: sortBy,
          start_date: getISOStringFromStringDate(startDate),
          end_date: getISOStringFromStringDate(endDate),
        },
      });

      action = Actions.receiveAntifraudList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveAntifraudError(e.message);
      }
    }

    action && dispatch(action);
  },

  fetchAntifraudCustomTestsList: () => async (dispatch, getState) => {
    const {
      antifraud: {
        data: {
          page,
          perPage,
          search,
          incidentLevel,
          integration,
          customTestId,
          startDate,
          endDate,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestAntifraud());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: ANTIFRAUDS_CUSTOM_TESTS_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          incident_level: incidentLevel || undefined,
          external_application_id: integration || undefined,
          sort: sortType,
          sort_by: sortBy,
          custom_test_id: customTestId,
          start_date: getISOStringFromStringDate(startDate),
          end_date: getISOStringFromStringDate(endDate),
        },
      });

      action = Actions.receiveAntifraudList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveAntifraudError(e.message);
      }
    }

    action && dispatch(action);
  },

  getAntifraudById:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestAntifraud());

      try {
        const response = await request({
          method: 'GET',
          url: `${ANTIFRAUDS_URL}/${id}`,
        });

        action = Actions.receiveRequestSuccess();
        onSuccess(response);
      } catch (e) {
        action = Actions.setLoading(false);
        onError(e);
      }

      dispatch(action);
    },

  updateAntifraud:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestUpdateComments());
      try {
        const eventObj = {
          id: data.id,
          comments: data.comments,
          incident_level: data.incidentLevel,
          external_application_id: data.integration,
        };

        const response = await request({
          method: 'PUT',
          url: `${ANTIFRAUDS_URL}/${eventObj.id}`,
          data: eventObj,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.setLoading(false));
        onError(e);
      }
    },

  updateAntifraudWithoutLoading:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      const requestData = formDataFromObj(data);
      try {
        const response = await request({
          method: 'PUT',
          url: `${ANTIFRAUDS_URL}/${data?.id}`,
          data: requestData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.setLoading(false));
        onError(e);
      }
    },

  updateAntifraudReviewed:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestUpdateAntifraud({ id: data.id }));

      const requestData = formDataFromObj(data);

      try {
        const response = await request({
          method: 'PUT',
          url: `${ANTIFRAUDS_URL}/${data.id}`,
          data: requestData,
        });

        dispatch(Actions.receiveUpdateAntifraudRequestSuccess());
        dispatch(Actions.updateAntifraudReviewedField(response.data.content));
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.setLoading(false));
        onError(e);
      }
    },

  fetchExportSubmit:
    ({ onSuccess, onError }) =>
    async (dispatch, getState) => {
      const {
        antifraud: {
          data: { search },
          exportFilter: {
            startDate,
            endDate,
            incidentLevel,
            integration,
            extension,
          },
        },
      } = getState();
      let action;
      const incident_level = incidentLevel === 'all' ? null : incidentLevel;
      const external_application_id =
        integration === 'all' ? null : integration;

      dispatch(Actions.requestExportLinks());

      const data = {
        file_type: extension,
        start_date: startDate,
        end_date: endDate,
        incident_level,
        external_application_id,
        search,
      };

      try {
        const response = await request({
          method: 'POST',
          url: ANTIFRAUD_EXPORT_URL,
          data,
        });

        action = Actions.receiveExportLinks(response.data.content);
        onSuccess(response.data.content);
      } catch (e) {
        action = Actions.receiveExportLinksError(e.message);
        onError();
      }

      dispatch(action);
    },
};

const { reducer } = antifraudSlice;

export { reducer, Actions, Async, Selectors };
