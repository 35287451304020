import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  BASE_TESTS_URL,
  CUSTOM_TESTS_URL,
  SIMULATION_RESULTS_URL,
  SIMULATION_RESULTS_BY_ID_URL,
  CUSTOM_TEST_RESULTS_URL,
  STUDENTS_BY_GROUP_URL,
  GROUPS_URL,
  UNITS_BY_GROUP_URL,
  SCHOOLS_BY_UNIT_URL,
  CLASSES_BY_SCHOOL_URL,
  CUSTOM_TEST_RESULTS_GENERATE_CERTIFICATE_URL,
  CUSTOM_TEST_RESULTS_GENERATE_REPORT_URL,
  SIMULATION_RESULTS_GENERATE_REPORT_URL,
  SIMULATION_RESULTS_EXPORT_URL,
  CUSTOM_TEST_RESULTS_EXPORT_URL,
  ANSWER_SHEETS_EXPORT_URL,
} from 'modules/Api/Routes';
import { defaultRequest } from 'modules/Utils/DefaultRequest';
import { getISOStringFromStringDate } from 'modules/Utils';
import {
  getUTCDefaultStartDate,
  getUTCDefaultEndDate,
} from 'modules/Utils/Date';
import { ConvertPlacementToProgressCheck } from 'modules/Utils';

const startDate = getUTCDefaultStartDate();
const endDate = getUTCDefaultEndDate();

let cancelToken;

const initialState = {
  loading: false,
  error: '',
  data: {
    page: 1,
    perPage: 10,
    link_answer_sheets: [],
    link_download_csv: '',
    link_download_xls: '',
    link_answer_sheets_csv: '',
    link_answer_sheets_xls: '',
    total: 0,
    search: '',
    groupId: '',
    unitId: '',
    schoolId: '',
    classId: '',
    testId: '',
    endDate: endDate,
    startDate: startDate,
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
  groups: {
    data: [],
    loading: false,
  },
  units: {
    data: [],
    loading: false,
  },
  schools: {
    data: [],
    loading: false,
  },
  classes: {
    data: [],
    loading: false,
  },
  tests: {
    data: [],
    selectedGroup: undefined,
    selectedUnit: undefined,
    selectedClass: undefined,
    selectedSchool: undefined,
    selectedTestId: undefined,
    loading: false,
  },
  groupStudents: {
    groupId: '',
    name: '',
    students: [],
    total: 0,
    page: 1,
    perPage: 8,
    search: '',
    showInfo: false,
    align: 'center',
    loading: false,
    error: null,
  },
  report: null,
  exportFilter: {
    data: {
      groups: {
        data: [],
        loading: false,
      },
      units: {
        data: [],
        loading: false,
      },
      schools: {
        data: [],
        loading: false,
      },
      classes: {
        data: [],
        loading: false,
      },
      tests: {
        data: [],
        loading: false,
      },
    },
    groupId: '',
    unitId: '',
    schoolId: '',
    classId: '',
    testId: '',
    startDate: startDate,
    endDate: endDate,
    extension: 'csv',
    category: '',
    link: '',
    loading: false,
  },
};

const simulationResultsSlice = createSlice({
  name: 'simulationResults',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    changeExportFilter: (state, action) => {
      state.exportFilter = {
        ...state.exportFilter,
        [action.payload?.param]: action.payload?.value,
      };
    },
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    requestSimulationResults: (state) => {
      state.loading = true;
      state.report = null;
      state.error = '';
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    /**
     * receive a success simulationResults list response
     */
    receiveSimulationBaseResultsList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        simulationResults: action.payload.simulate_results,
        link_download_csv: action.payload.link_download_csv,
        link_download_xls: action.payload.link_download_xls,
        link_answer_sheets_csv: action.payload.link_answer_sheets_csv,
        link_answer_sheets_xls: action.payload.link_answer_sheets_xls,
        total: action.payload.total_items,
      };
      state.tests = {
        ...state.tests,
        selectedTestId: action.payload.test_id || state.tests.selectedTestId,
      };
    },
    receiveSimulationResultsList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        simulationResults: action.payload.custom_test_results,
        link_answer_sheets: action.payload?.link_answer_sheets,
        link_download_csv: action.payload.link_download_csv,
        link_download_xls: action.payload.link_download_xls,
        link_answer_sheets_csv: action.payload.link_answer_sheets_csv,
        link_answer_sheets_xls: action.payload.link_answer_sheets_xls,
        total: action.payload.total_items,
      };
    },
    updateDate: (state, action) => {
      state.groups.currentStartDate =
        action.payload.start_date || state.groups.currentStartDate;
      state.groups.currentEndDate =
        action.payload.end_date || state.groups.currentEndDate;
    },
    clearSimulationResultsList: (state) => {
      state.data = {
        ...state.data,
        simulationResults: [],
        link_answer_sheets: [],
        link_download_csv: '',
        link_download_xls: '',
        link_answer_sheets_csv: '',
        link_answer_sheets_xls: '',
        total: 0,
        page: 1,
        perPage: 10,
        search: '',
        groupId: '',
        unitId: '',
        schoolId: '',
        classId: '',
        testId: '',
        endDate,
        startDate,
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
    },
    resetPage: (state) => {
      state.data.page = 1;
    },
    clearTests: (state) => {
      state.tests = {
        data: [],
        loading: false,
      };
    },
    /**
     * receive an error response
     */
    receiveSimulationResultsError: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        simulationResults: [],
        total: 0,
      };
      state.error = action.payload;
    },
    changeSimulationResultsPage: (state, action) => {
      state.data.page = action.payload;
    },
    changeSimulationResultsSearch: (state, action) => {
      state.data.page = 1;
      state.data.search = action.payload?.search || '';
    },
    changeSimulationResultsFilters: (state, action) => {
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
      state.data.groupId = action?.payload?.group_id || null;
      state.data.unitId = action?.payload?.unit_id || null;
      state.data.schoolId = action?.payload?.school_id || null;
      state.data.classId = action?.payload?.class_id || null;
      state.data.testId = action?.payload?.test_id || null;
      state.data.studentId = action?.payload?.studentId || null;
      state.data.search = action?.payload?.search || '';
      state.data.startDate = action.payload?.start_date || state.data.startDate;
      state.data.endDate = action.payload?.end_date || state.data.endDate;

      state.exportFilter.groupId = parseInt(action?.payload?.group_id) || '';
      state.exportFilter.unitId = parseInt(action?.payload?.unit_id) || '';
      state.exportFilter.schoolId = parseInt(action?.payload?.school_id) || '';
      state.exportFilter.classId = parseInt(action?.payload?.class_id) || '';
      state.exportFilter.testId = parseInt(action?.payload?.test_id) || '';
      state.exportFilter.startDate =
        action.payload?.start_date || state.data.startDate;
      state.exportFilter.endDate =
        action.payload?.end_date || state.data.endDate;
    },
    requestGroups: (state) => {
      state.groups = {
        ...state.groups,
        loading: true,
      };
      state.exportFilter.data.groups = {
        data: [],
        loading: true,
      };
      state.error = '';
    },
    receiveGroupsError: (state, action) => {
      state.groups = {
        ...state.groups,
        loading: false,
      };
      state.exportFilter.data.groups = {
        ...state.exportFilter.data.groups,
        loading: false,
      };
      state.error = action.payload;
    },
    receiveGroupsList: (state, action) => {
      state.groups = {
        ...state.groups,
        loading: false,
        data: action.payload.content.groups,
      };
      state.exportFilter.data.groups = {
        data: action.payload.content.groups,
        loading: false,
      };
    },
    requestBaseTestsBy: (state) => {
      state.tests = {
        ...state.tests,
        data: [],
        loading: true,
      };
      state.exportFilter.data.tests = {
        data: [],
        loading: true,
      };
      state.error = '';
      state.report = null;
    },
    clearError: (state) => {
      state.error = '';
    },
    receiveBaseTestsError: (state, action) => {
      state.tests = {
        ...state.tests,
        loading: false,
        data: [],
      };
      state.exportFilter.data.tests = {
        data: [],
        loading: false,
      };
      state.error = action.payload;
    },
    receiveBaseTestsList: (state, action) => {
      state.tests = {
        ...state.tests,
        loading: false,
        data: ConvertPlacementToProgressCheck(action.payload.tests),
      };
      state.exportFilter.data.tests = {
        data: ConvertPlacementToProgressCheck(action.payload.tests),
        loading: false,
      };
    },
    receiveCustomTests: (state, action) => {
      state.tests = {
        ...state.tests,
        loading: false,
        selectedGroup: action.payload.selected.group_id,
        selectedUnit: action.payload.selected.unit_id,
        selectedClass: action.payload.selected.class_id,
        selectedSchool: action.payload.selected.school_id,
        data: action.payload.custom_tests,
      };
      state.exportFilter.data.tests = {
        data: action.payload.custom_tests,
        loading: false,
      };
    },
    requestUnits: (state) => {
      state.units = {
        data: [],
        loading: true,
      };
      state.exportFilter.data.units = {
        data: [],
        loading: true,
      };
      state.error = '';
    },
    receiveUnitsList: (state, action) => {
      state.units = {
        loading: false,
        data: action.payload.content,
      };
      state.exportFilter.data.units = {
        data: action.payload.content,
        loading: false,
      };
    },
    receiveUnitsError: (state, action) => {
      state.units = {
        ...state.units,
        loading: false,
      };
      state.exportFilter.data.units = {
        ...state.exportFilter.data.units,
        loading: false,
      };
      state.error = action.payload;
    },
    requestSchools: (state) => {
      state.schools = {
        data: [],
        loading: true,
      };
      state.exportFilter.data.schools = {
        data: [],
        loading: true,
      };
      state.error = '';
    },
    receiveSchoolsError: (state, action) => {
      state.schools = {
        ...state.schools,
        loading: false,
      };
      state.exportFilter.data.schools = {
        ...state.exportFilter.data.schools,
        loading: false,
      };
      state.error = action.payload;
    },
    receiveSchoolsList: (state, action) => {
      state.schools = {
        ...state.schools,
        loading: false,
        data: action.payload?.content?.schools,
      };
      state.exportFilter.data.schools = {
        data: action.payload.content?.schools,
        loading: false,
      };
    },
    receiveClassesError: (state, action) => {
      state.classes = {
        ...state.classes,
        loading: false,
      };
      state.exportFilter.data.classes = {
        ...state.exportFilter.data.classes,
        loading: false,
      };
      state.error = action.payload;
    },
    receiveStudentsList: (state, action) => {
      state.groupStudents = {
        ...state.groupStudents,
        groupId: action.payload.id || state.groupStudents.groupId,
        name: action.payload.name || state.groupStudents.name,
        students: action.payload.students,
        total: action.payload.total_items,
        loading: false,
      };
    },
    updateGroupStudentsSearch: (state, action) => {
      state.groupStudents.search = action.payload.search;
    },
    changeGroupFormPage: (state, action) => {
      state.groupStudents.page = action.payload;
    },
    requestClasses: (state) => {
      state.classes = {
        data: [],
        loading: true,
      };
      state.exportFilter.data.classes = {
        data: [],
        loading: true,
      };
      state.error = '';
    },
    requestStudents: (state) => {
      state.groupStudents.loading = true;
      state.groupStudents.error = null;
    },
    receiveStudentsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    receiveClassesList: (state, action) => {
      state.classes = {
        ...state.classes,
        loading: false,
        data: action.payload.content,
      };
      state.exportFilter.data.classes = {
        data: action.payload.content,
        loading: false,
      };
    },
    updateSelected: (state, action) => {
      state.tests = {
        ...state.tests,
        selectedGroup: action.payload?.group || state.tests.selectedGroup,
        selectedUnit: action.payload?.unit || state.tests.selectedUnit,
        selectedClass: action.payload?.class || state.tests.selectedClass,
        selectedSchool: action.payload?.school || state.tests.selectedSchool,
        selectedTestId: action.payload?.testId || state.tests.selectedTestId,
      };
    },
    changeResultSearch: (state, action) => {
      state.data.search = action.payload.search;
      state.data.page = 1;
      state.report = null;
    },
    clearStudents: (state) => {
      state.groupStudents.search = '';
      state.groupStudents.page = 1;
    },
    clearFilters: (state) => {
      state.data.search = '';
      state.data.page = 1;
      state.data.simulationResults = [];
      state.units.data = [];
      state.schools.data = [];
      state.classes.data = [];
      state.tests = {
        ...state.tests,
        data: [],
        selectedUnit: undefined,
        selectedClass: undefined,
        selectedSchool: undefined,
        selectedTestId: undefined,
      };
    },
    receiveReport: (state, action) => {
      state.loading = false;
      state.report = action.payload;
    },
    clearReport: (state) => {
      state.report = null;
    },

    requestExportUnits: (state) => {
      state.exportFilter.data.units = {
        ...state.exportFilter.data.units,
        loading: true,
      };

      state.exportFilter.data.schools = {
        data: [],
        loading: false,
      };

      state.exportFilter.data.classes = {
        data: [],
        loading: false,
      };

      state.exportFilter.unitId = '';
      state.exportFilter.schoolId = '';
      state.exportFilter.classId = '';
      state.exportFilter.testId = '';
    },

    receiveExportUnitsError: (state, action) => {
      state.exportFilter.data.units = {
        ...state.exportFilter.data.units,
        loading: false,
      };
      state.error = action.payload;
    },

    receiveExportUnitsList: (state, action) => {
      state.exportFilter.data.units = {
        data: action.payload.content,
        loading: false,
      };
    },

    requestExportSchools: (state) => {
      state.exportFilter.data.schools = {
        ...state.exportFilter.data.schools,
        loading: true,
      };

      state.exportFilter.data.classes = {
        data: [],
        loading: false,
      };

      state.exportFilter.schoolId = '';
      state.exportFilter.classId = '';
      state.exportFilter.testId = '';
    },

    receiveExportSchoolsError: (state, action) => {
      state.exportFilter.data.schools = {
        ...state.exportFilter.data.schools,
        loading: false,
      };
      state.error = action.payload;
    },

    receiveExportSchoolsList: (state, action) => {
      state.exportFilter.data.schools = {
        data: action.payload?.content?.schools,
        loading: false,
      };
    },

    requestExportClasses: (state) => {
      state.exportFilter.data.classes = {
        ...state.exportFilter.data.classes,
        loading: true,
      };

      state.exportFilter.classId = '';
      state.exportFilter.testId = '';
    },

    receiveExportClassesError: (state, action) => {
      state.exportFilter.data.classes = {
        ...state.exportFilter.data.classes,
        loading: false,
      };
      state.error = action.payload;
    },

    receiveExportClassesList: (state, action) => {
      state.exportFilter.data.classes = {
        data: action.payload.content,
        loading: false,
      };
    },

    requestExportCustomTests: (state) => {
      state.exportFilter.data.tests = {
        ...state.exportFilter.data.tests,
        loading: true,
      };

      state.exportFilter.testId = '';
    },

    receiveExportCustomTestsError: (state, action) => {
      state.exportFilter.data.tests = {
        ...state.exportFilter.data.tests,
        loading: false,
      };
      state.error = action.payload;
    },

    receiveExportCustomTestsList: (state, action) => {
      state.exportFilter.data.tests = {
        data: action.payload.custom_tests,
        loading: false,
      };
    },

    clearExportTests: (state) => {
      state.exportFilter.data.tests = {
        data: [],
        loading: false,
      };

      state.exportFilter.testId = '';
    },

    requestExportLinks: (state) => {
      state.exportFilter.link = '';
      state.exportFilter.loading = true;
    },

    receiveExportLinks: (state, action) => {
      state.exportFilter.loading = false;
      state.exportFilter.link =
        action?.payload?.[`link_download_${state.exportFilter.extension}`];
    },

    receiveExportLinksError: (state, action) => {
      state.exportFilter.loading = false;
      state.error = action.payload;
    },
  },
});

const Actions = simulationResultsSlice.actions;

const Selectors = {
  fetchListData: (state) => state.simulationResults,
  simulationResultsLoading: ({ simulationResults: { loading } }) => ({
    loading,
  }),
};

const Async = {
  fetchSimulationResults: () => async (dispatch, getState) => {
    const {
      simulationResults: {
        data: {
          page,
          perPage,
          groupId,
          unitId,
          schoolId,
          classId,
          testId,
          search,
          startDate,
          endDate,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    if (!testId) return;

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestSimulationResults());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: SIMULATION_RESULTS_URL,
        params: {
          search,
          group_id: groupId && groupId !== 'no-group' ? groupId : null,
          no_group: groupId === 'no-group',
          unit_id: unitId,
          school_id: schoolId,
          class_id: classId,
          test_id: testId,
          start_date: getISOStringFromStringDate(startDate),
          end_date: getISOStringFromStringDate(endDate),
          page,
          paginates_per: perPage,
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receiveSimulationBaseResultsList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveSimulationResultsError(e.message);
      }
    }

    action && dispatch(action);
  },

  getSimulationResultsById:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestSimulationResults());

      try {
        const response = await request({
          method: 'GET',
          url: `${SIMULATION_RESULTS_BY_ID_URL}?id=${id}`,
        });

        action = Actions.receiveRequestSuccess();
        onSuccess(response);
      } catch (e) {
        action = Actions.setLoading(false);
        onError(e);
      }

      dispatch(action);
    },

  fetchBaseTests: () => async (dispatch) => {
    let action;

    dispatch(Actions.requestBaseTestsBy());

    try {
      const response = await request({
        method: 'GET',
        url: BASE_TESTS_URL,
        params: {
          sort: 'ASC',
          sort_by: 'name',
        },
      });

      action = Actions.receiveBaseTestsList(response.data.content);
    } catch (e) {
      action = Actions.receiveBaseTestsError(e.message);
    }

    dispatch(action);
  },

  fetchCustomTests:
    ({ group_id, unit_id, class_id, school_id }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestBaseTestsBy());

      try {
        const response = await request({
          method: 'GET',
          url: CUSTOM_TESTS_URL,
          params: {
            group_id,
            unit_id,
            school_id,
            sort: 'ASC',
            sort_by: 'name',
          },
        });

        const data = {
          ...response.data.content,
          selected: { group_id, unit_id, class_id, school_id },
        };

        action = Actions.receiveCustomTests(data);
      } catch (e) {
        action = Actions.receiveBaseTestsError(e.message);
      }

      dispatch(action);
    },

  fetchCustomTestsSimulationResults: () => async (dispatch, getState) => {
    const {
      simulationResults: {
        data: {
          page,
          perPage,
          groupId,
          unitId,
          schoolId,
          classId,
          testId,
          search,
          startDate,
          endDate,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    if (!testId) return;

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestSimulationResults());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: CUSTOM_TEST_RESULTS_URL,
        params: {
          search,
          group_id: groupId,
          unit_id: unitId,
          school_id: schoolId,
          class_id: classId,
          custom_test_id: testId,
          start_date: getISOStringFromStringDate(startDate),
          end_date: getISOStringFromStringDate(endDate),
          page,
          paginates_per: perPage,
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receiveSimulationResultsList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveSimulationResultsError(e.message);
      }
    }

    action && dispatch(action);
  },

  fetchGroupStudents:
    ({ group_id, search } = {}) =>
    async (dispatch, getState) => {
      const {
        simulationResults: {
          groupStudents: {
            page,
            perPage,
            search: searchName,
            groupId,
            name: groupName,
          },
        },
      } = getState();

      let action;

      search && dispatch(Actions.updateGroupStudentsSearch({ search }));
      dispatch(Actions.requestStudents());

      try {
        const response = await request({
          method: 'GET',
          url: STUDENTS_BY_GROUP_URL,
          params: {
            page,
            paginates_per: perPage,
            group_id: group_id || groupId,
            search: search || searchName,
          },
        });

        const data = {
          ...response.data.content,
          id: group_id || groupId,
          name: name || groupName,
        };

        action = Actions.receiveStudentsList(data);
      } catch (e) {
        action = Actions.receiveStudentsError(e.message);
      }

      dispatch(action);
    },

  fetchGroupsList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      `${GROUPS_URL}?page=1&paginates_per=200`,
      'GET',
      Actions.requestGroups,
      Actions.receiveGroupsList,
      Actions.receiveGroupsError
    ),

  fetchUnitsByGroupList:
    ({ group_id }) =>
    async (dispatch) => {
      defaultRequest(
        dispatch,
        `${UNITS_BY_GROUP_URL}?group_id=${group_id}`,
        'GET',
        Actions.requestUnits,
        Actions.receiveUnitsList,
        Actions.receiveUnitsError
      );
    },

  fetchSchoolsByUnitList:
    ({ unit_id }) =>
    async (dispatch) =>
      defaultRequest(
        dispatch,
        `${SCHOOLS_BY_UNIT_URL}?unit_id=${unit_id}`,
        'GET',
        Actions.requestSchools,
        Actions.receiveSchoolsList,
        Actions.receiveSchoolsError
      ),

  fetchClassesBySchoolList:
    ({ school_id }) =>
    async (dispatch) =>
      defaultRequest(
        dispatch,
        `${CLASSES_BY_SCHOOL_URL}?school_id=${school_id}`,
        'GET',
        Actions.requestClasses,
        Actions.receiveClassesList,
        Actions.receiveClassesError
      ),

  generateCertificatePDF:
    ({ id, studentId, onSuccess, onError }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestSimulationResults());

      try {
        const response = await request({
          method: 'GET',
          url: `${CUSTOM_TEST_RESULTS_GENERATE_CERTIFICATE_URL}/${studentId}/${id}`,
          responseType: 'blob',
        });

        action = Actions.receiveRequestSuccess();
        onSuccess(response);
      } catch (e) {
        action = Actions.setLoading(false);
        onError(e);
      }

      dispatch(action);
    },

  generateELLTReport:
    ({ id, studentId, onSuccess, onError }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestSimulationResults());

      try {
        const response = await request({
          method: 'GET',
          url: `${CUSTOM_TEST_RESULTS_GENERATE_REPORT_URL}/${studentId}/${id}`,
          responseType: 'blob',
        });

        action = Actions.receiveRequestSuccess();
        onSuccess(response);
      } catch (e) {
        action = Actions.setLoading(false);
        onError(e);
      }

      dispatch(action);
    },

  generateReport:
    ({ exercise_id, student_id, onSuccess, onError }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestSimulationResults());

      try {
        const response = await request({
          method: 'GET',
          url: SIMULATION_RESULTS_GENERATE_REPORT_URL,
          params: {
            exercise_id,
            student_id,
          },
        });

        action = Actions.receiveReport(response.data);
        onSuccess(response.data);
      } catch (e) {
        action = Actions.setLoading(false);
        onError(e);
      }

      dispatch(action);
    },

  fetchExportUnits:
    ({ group_id }) =>
    async (dispatch) =>
      defaultRequest(
        dispatch,
        `${UNITS_BY_GROUP_URL}?group_id=${group_id}`,
        'GET',
        Actions.requestExportUnits,
        Actions.receiveExportUnitsList,
        Actions.receiveExportUnitsError
      ),

  fetchExportSchools:
    ({ unit_id }) =>
    async (dispatch) =>
      defaultRequest(
        dispatch,
        `${SCHOOLS_BY_UNIT_URL}?unit_id=${unit_id}`,
        'GET',
        Actions.requestExportSchools,
        Actions.receiveExportSchoolsList,
        Actions.receiveExportSchoolsError
      ),

  fetchExportClasses:
    ({ school_id }) =>
    async (dispatch) =>
      defaultRequest(
        dispatch,
        `${CLASSES_BY_SCHOOL_URL}?school_id=${school_id}`,
        'GET',
        Actions.requestExportClasses,
        Actions.receiveExportClassesList,
        Actions.receiveExportClassesError
      ),

  fetchExportCustomTests:
    ({ group_id, unit_id, school_id }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestExportCustomTests());

      try {
        const response = await request({
          method: 'GET',
          url: CUSTOM_TESTS_URL,
          params: {
            group_id,
            unit_id,
            school_id,
            sort: 'ASC',
            sort_by: 'name',
          },
        });

        const data = response.data.content;

        action = Actions.receiveExportCustomTestsList(data);
      } catch (e) {
        action = Actions.receiveExportCustomTestsError(e.message);
      }

      dispatch(action);
    },

  fetchExportSubmitBase:
    ({ onSuccess, onError }) =>
    async (dispatch, getState) => {
      const {
        simulationResults: {
          data: { search },
          exportFilter: {
            groupId,
            unitId,
            schoolId,
            classId,
            testId,
            startDate,
            endDate,
            extension,
            show_subcategory_scores,
          },
        },
      } = getState();
      let action;

      dispatch(Actions.requestExportLinks());

      const data = {
        test_id: testId,
        file_type: extension,
        start_date: getISOStringFromStringDate(startDate),
        end_date: getISOStringFromStringDate(endDate),
        search,
        show_subcategory_scores,
      };

      groupId && (data.group_id = groupId);
      unitId && (data.unit_id = unitId);
      schoolId && (data.school_id = schoolId);
      classId && (data.class_id = classId);

      try {
        const response = await request({
          method: 'POST',
          url: SIMULATION_RESULTS_EXPORT_URL,
          data,
        });

        action = Actions.receiveExportLinks(response.data.content);
        onSuccess(response.data.content);
      } catch (e) {
        action = Actions.receiveExportLinksError(e.message);
        onError();
      }

      dispatch(action);
    },

  fetchExportSubmitCustom:
    ({ isResultsAnswerSheets, onSuccess, onError }) =>
    async (dispatch, getState) => {
      const {
        simulationResults: {
          data: { search },
          exportFilter: {
            groupId,
            unitId,
            schoolId,
            classId,
            testId,
            startDate,
            endDate,
            extension,
            show_subcategory_scores,
          },
        },
      } = getState();
      let action;

      dispatch(Actions.requestExportLinks());

      const data = {
        custom_test_id: testId,
        file_type: extension,
        start_date: getISOStringFromStringDate(startDate),
        end_date: getISOStringFromStringDate(endDate),
        search,
        show_subcategory_scores,
      };

      groupId && (data.group_id = groupId);
      unitId && (data.unit_id = unitId);
      schoolId && (data.school_id = schoolId);
      classId && (data.class_id = classId);

      try {
        const response = await request({
          method: 'POST',
          url: isResultsAnswerSheets
            ? ANSWER_SHEETS_EXPORT_URL
            : CUSTOM_TEST_RESULTS_EXPORT_URL,
          data,
        });

        action = Actions.receiveExportLinks(response.data.content);
        onSuccess(response.data.content);
      } catch (e) {
        action = Actions.receiveExportLinksError(e.message);
        onError();
      }

      dispatch(action);
    },
};

const reducer = simulationResultsSlice.reducer;

export { reducer, Actions, Async, Selectors };
