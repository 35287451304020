import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isValid } from 'date-fns';

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  convertUserTimezoneToUTC,
  convertUTCDateToUserTimezone,
} from 'modules/Utils/Date';
import { Selectors } from 'modules/Auth/AuthSlice';
import { useSelector } from 'react-redux';
import { CalendarIcon, ArrowDownIcon } from '@edusynch/edusynch-svg-icons';
import * as S from './MUIPicker.styles';
import './MUIPicker.styles.css';

const theme = createTheme({
  typography: {
    fontSize: 20,
  },
});

const Field = styled(TextField)`
  input {
    color: ${(props) => props.theme.config.colors.text_base} !important;
  }
`;

export const MUIDatePicker = ({
  date,
  onChange,
  textFieldProps,
  inputClick,
  inputTransparent,
  inputClassname,
  borders,
  noIcon,
  floatingPlaceholder,
  disabled,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [visibleFloatingPlaceholder, setVisibleFloatingPlaceholder] = useState(
    date && floatingPlaceholder
  );
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { user } = useSelector(Selectors.selectAuthData);
  let dateValue = user?.timezone
    ? convertUTCDateToUserTimezone(date, user.timezone)
    : date;

  useEffect(() => {
    if (date || props?.value) {
      floatingPlaceholder && setVisibleFloatingPlaceholder(true);
      setShowPlaceholder(false);
    }
  }, [date, props?.value]);

  const handleChange = (item) => {
    if (isValid(item) && user?.timezone) {
      const newDateUtc = convertUserTimezoneToUTC(item, user?.timezone);
      return onChange(newDateUtc);
    }
    return (isValid(item) || !item) && onChange(item);
  };

  return (
    <S.Container
      borders={borders}
      inputTransparent={inputTransparent}
      disabled={disabled}
    >
      {floatingPlaceholder && textFieldProps.placeholder && (
        <S.FloatingPlaceholder
          isDisabled={disabled}
          show={visibleFloatingPlaceholder}
        >
          <span>{textFieldProps.placeholder}</span>
        </S.FloatingPlaceholder>
      )}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack spacing={3}>
          <ThemeProvider theme={theme}>
            {showPlaceholder && !visibleFloatingPlaceholder && (
              <S.Placeholder>{textFieldProps.placeholder}</S.Placeholder>
            )}
            <DesktopDatePicker
              value={dateValue}
              onChange={handleChange}
              open={open}
              onOpen={handleOpen}
              onClose={handleClose}
              disabled={disabled}
              renderInput={(params) => (
                <Field
                  {...params}
                  className={inputClassname}
                  onClick={() => inputClick && handleOpen()}
                  InputProps={{
                    startAdornment: !noIcon && (
                      <CalendarIcon className="left" />
                    ),
                    endAdornment: (
                      <S.ArrowDown onClick={handleOpen}>
                        <ArrowDownIcon />
                      </S.ArrowDown>
                    ),
                  }}
                />
              )}
              {...props}
            />
          </ThemeProvider>
        </Stack>
      </LocalizationProvider>
    </S.Container>
  );
};

MUIDatePicker.propTypes = {
  date: PropTypes.any,
  onChange: PropTypes.func,
  textFieldProps: PropTypes.object,
  inputClick: PropTypes.bool,
  inputTransparent: PropTypes.bool,
  borders: PropTypes.bool,
  noIcon: PropTypes.bool,
  value: PropTypes.any,
  floatingPlaceholder: PropTypes.bool,
  disabled: PropTypes.bool,
  inputClassname: PropTypes.string,
};
